.tab {
  .tab-nav {
    display: flex;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
      @include themify($themes) {
        border: 1px solid themed("border");
        background-color: themed("content");
      }
      border-radius: 0.25rem;
    }

    .nav-item {
      @include media-breakpoint-up(lg) {
        border-radius: 0;
        overflow: hidden;
        @include themify($themes) {
          border-top: 1px solid themed("border");
          border-bottom: 1px solid themed("border");
          border-right: 1px solid themed("border");
        }
      }

      &:last-child {
        @include media-breakpoint-up(lg) {
          border-bottom-right-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
        }
      }

      &:first-child {
        @include media-breakpoint-up(lg) {
          border-bottom-left-radius: 0.25rem;
          border-top-left-radius: 0.25rem;
          @include themify($themes) {
            border-left: 1px solid themed("border");
          }
        }
      }

      &:not(:last-child) .nav-link {
        @include media-breakpoint-down(md) {
          @include themify($themes) {
            border-bottom: 1px solid themed("border");
          }
        }
      }

      .nav-link {
        border-bottom: 0;
        border-radius: 0;

        &.active {
          @include themify($themes) {
            background-color: themed("button");
            color: themed("active");
          }
        }

        &:not(.active):hover {
          @include media-breakpoint-up(lg) {
            @include themify($themes) {
              background-color: themed("background");
              color: themed("active");
            }
          }
          @include media-breakpoint-down(md) {
            @include themify($themes) {
              background-color: themed("content");
              color: themed("active");
            }
          }
        }
      }
    }
  }
}
