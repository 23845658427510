$desktop: 75px;
$mobile: 60px;

.video-height {
  height: calc(100vh - #{$desktop});
  @include media-breakpoint-down(md){
    height: calc(100vh - #{$mobile});
  }
}

.scroll_offset {
    scroll-margin-top: $desktop;
  @include media-breakpoint-down(md){
    scroll-margin-top: $mobile;
  } 
}

.header-placeholder {
  height: $desktop;
  @include media-breakpoint-down(md){
    height: $mobile;
  }
}

.header-container {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
}

header {
  margin: 0;
  padding: 0;
  @include themify($themes) {
    background-color: themed("content");
    box-shadow: 2px 2px 2px 1px themed("border");
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-up(xl) {
      max-width: map-get($grid-breakpoints, xl);
      padding: 0 #{$spacer * 3};
    }
    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      width: 100%;
    }
    @include media-breakpoint-only(lg) {
      max-width: map-get($grid-breakpoints, lg);
      padding: 0 #{$spacer * 2};
    }
    @include media-breakpoint-only(lg) {
      max-width: map-get($grid-breakpoints, lg);
      padding: 0 #{$spacer * 2};
    }
    @include media-breakpoint-down(md) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    > :first-child {
      display: flex;
      flex-direction: row;
      width: 100%;

      .logo {
        margin: 0 auto 0 0;
        padding: 0 0 0 #{$spacer};

        svg {
          @include media-breakpoint-up(lg) {
            height: calc(#{$desktop} - #{$spacer * 2});
            margin: #{$spacer} 0;
          }
          @include media-breakpoint-only(lg) {
            width: 100%;
            margin: 0 auto 0 0;
          }
          @include media-breakpoint-down(md) {
            position: absolute;
            height: calc(#{$mobile} - #{$spacer * 0.75 * 2});
            margin: #{$spacer * 0.75} 0;
          }
        }
      }
    }

    input#dark-mode-toggler {
      display: none;
    }

    #dark-mode-toggler:checked + .dark-mode-toggler {
      svg {
        transform: rotate(180deg);
        transition: 0.3s;
      }
    }

    #dark-mode-toggler:not(:checked) + .dark-mode-toggler {
      svg {
        transform: rotate(0deg);
        transition: 0.3s;
      }
    }

    .dark-mode-toggler {
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        display: none;
      }
      @include media-breakpoint-down(md) {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: #{$mobile};
        justify-content: space-around;
        margin: 0;
        padding: #{$spacer * 0.8};
        width: #{$spacer * 3};

        &:hover {
          .line {
            @include themify($themes) {
              background-color: #00a9f4;
            }
          }
        }
      }
    }

    input#nav-main-toggler {
      display: none;

      &:checked + label > .line:nth-child(1) {
        transform: translateY(9.24px) rotate(-45deg);
      }

      &:checked + label > .line:nth-child(2) {
        opacity: 0;
      }

      &:checked + label > .line:nth-child(3) {
        transform: translateY(-9.24px) rotate(45deg);
      }
    }

    .nav-main-toggler {
      @include media-breakpoint-up(lg) {
        display: none;
      }
      @include media-breakpoint-down(md) {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: #{$mobile};
        justify-content: space-around;
        margin: 0;
        padding: #{$spacer};
        width: #{$mobile};

        &:hover {
          .line {
            @include themify($themes) {
              background-color: #00a9f4;
            }
          }
        }
      }

      .line {
        @include themify($themes) {
          background-color: themed("primary");
        }
        display: block;
        height: 3px;
        width: 100%;
        z-index: 1005;
        transition: 0.5s;
        transform-origin: center;
      }
    }

    ul#nav-main {
      //background: linear-gradient(to bottom,#e6e6e6 0,#fff 5px,#fff 8px,#fff 100%);
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        width: 100%;
        display: none;
/*         @include themify($themes) {
          border-bottom: 3px solid themed("active");
        } */
      }

      &.expand {
        @include media-breakpoint-down(md) {
          display: block;
        }
      }

      > li {
        list-style: none;
        white-space: nowrap;

        @include media-breakpoint-down(md) {
          /* @include themify($themes) {
            border-top: 1px solid themed("border");
          } */
          margin: 30px 0;
        }

        /*         .dark-mode-toggler {
          @include media-breakpoint-up(lg) {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: #{$desktop};
            justify-content: space-around;
            margin: 0;
            padding: #{$spacer * 0.8};
            width: #{$spacer * 3};

            &:hover {
              .line {
                @include themify($themes) {
                  background-color: #00a9f4;
                }
              }
            }
          }
          @include media-breakpoint-down(md) {
            display: none;
          }
        } */

        /*         .kontakt-btn {
          font-family: "Poppins", sans-serif !important;

          @include media-breakpoint-down(md) {
            border-radius: 0;
            padding: #{$spacer * 0.5} #{$spacer};
            width: 100%;
            font-weight: 600;
            font-size: 1.2rem;
            text-align: left;
            @include themify($themes) {
              border: none;
              background-color: themed("content");
              color: themed("primary");
            }
          }
        } */

        input {
          display: none;
        }

        .nav-sub-toggler {
          margin: 0 10px;
          font-family: "Poppins", sans-serif !important;
          font-weight: 600;

          @include media-breakpoint-up(lg) {
            @include themify($themes) {
              color: themed("primary");
            }
            display: flex;
            flex-direction: row;
            height: #{$desktop};
            justify-content: center;
            align-items: center;
            padding: 3px 5px;
            text-decoration: none;
            transition: opacity 0.3s ease-in-out;

            &:hover {
              @include themify($themes) {
                color: themed("active");
              }
              text-decoration: none;

              ~ .nav-sub {
                opacity: 1;
                display: block;
              }
            }
          }
          @include media-breakpoint-down(md) {
            @include themify($themes) {
              color: themed("primary");
            }
            font-size: 1.2rem;
            padding: #{$spacer * 0.5} #{$spacer};
            text-decoration: none;
            width: 100%;
          }
        }

        /* ul {
          //-------------
          @include media-breakpoint-up(lg) {
            @include themify($themes) {
              background-color: themed("background");
              box-shadow: inset 0 2px 2px -2px rgba(0, 0, 0, 0.5),
                0 3px 2px 2px themed("border");
              border-bottom: 3px solid themed("active");
            }
            display: none;
            width: 100%;
            animation-name: showNav-sub;
            animation-duration: 0s;
            animation-timing-function: ease;
            animation-delay: 0s;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: none;
            animation-play-state: running;
            animation-duration: 0.2s;
            animation-fill-mode: forwards;
            padding: 0;
            margin: 0;
            width: 100%;
            padding-bottom: 5px;
            position: relative;
            @keyframes showNav-sub {
              0% {
                height: auto;
                opacity: 0;
                padding-top: 0;
              }

              100% {
                height: auto;
                opacity: 1;
                padding-top: 5px;
              }
            }

            &:hover {
              display: block;
            }
          }
          @include media-breakpoint-down(md) {
            display: none;
            padding-left: 0;
            @include themify($themes) {
              background-color: themed("background");
            }
            width: 100%;

            &.expand {
              display: block;
            }
          }

          li {
            list-style: none;
            white-space: nowrap;
            @include media-breakpoint-up(lg) {
              height: 44px;
            }
            @include media-breakpoint-down(md) {
              padding-left: #{$spacer * 2};
              @include themify($themes) {
                border-top: 1px solid themed("border");
              }
            }

            &.active {
              @include media-breakpoint-up(lg) {
                @include themify($themes) {
                  background-color: themed("border");

                  a {
                    color: themed("primary");
                    border-left: 3px solid themed("active");
                  }
                }
              }
              @include media-breakpoint-down(md) {
                @include themify($themes) {
                  background-color: themed("border");

                  a {
                    color: themed("primary");
                  }
                }
              }
            }

            a {
              @include media-breakpoint-up(lg) {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                padding: 3px #{$spacer};
              }
              @include media-breakpoint-down(md) {
                display: block;
                padding: #{$spacer * 0.5} 0;
                width: 100%;
              }
            }
          }
        } */
      }
    }
  }
}
