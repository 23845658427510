@import "_colorthemes.scss";

@import "_variables.scss";
@import "bootstrap/bootstrap.scss";

@import "_logo.scss";
@import "_bewerbung.scss";

@import "_lazyload.scss";
@import "_icons.scss";

@import "_general.scss";
@import "_header.scss";
@import "_content.scss";
@import "_footer.scss";

@import "_timeline.scss";
@import "_impressum.scss";
@import "_hero.scss";
@import "_tab.scss";
@import "_parallax_images.scss";
@import "_accordion.scss";