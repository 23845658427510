.event-container {
  width: 3000vw;
}
.event {
    width: 100vw;
  }


@include media-breakpoint-up(md) {
  .event-container {
    width: 2500vw;
  }
  .event {
    width: 75vw;
  }
}

@include media-breakpoint-up(lg) {
  .event-container {
    width: 2000vw;
  }
  .event {
    width: 50vw;
  }
}
