// Override Bootstrap variables
$primary:                           $hcdarkblue;
$secondary:                         $hclightblue;
$info:                              $hclightblue;
$success:                           $hcgreen;
$warning:                           $hcyellow;
$danger:                            $hcred;

$font-family-sans-serif:            'Poppins', sans-serif !important;
$enable-responsive-font-sizes:      true;
$grid-gutter-width:                 1rem;
