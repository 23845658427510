
.parallax_container {
  height: 250px;
  width: 250px;
  @include media-breakpoint-up(md) {
    min-height: 300px;
    min-width: 300px;
  }
  @include media-breakpoint-up(lg) {
    min-height: 480px;
    min-width: 480px;
  }
}

.parallax_image {
  max-height: 150px;
  max-width: 150px;

  @include media-breakpoint-up(md) {
    height: 230px;
    width: 230px;
  }
  @include media-breakpoint-up(lg) {
    max-height: none;
    max-width: 50%;
  }
}

.parallax_image_front {
  top: 100px;

  @include media-breakpoint-up(md) {
    top: 130px;
  }
  @include media-breakpoint-up(lg) {
    top: 200px;
  }
}

.parallax_image_back {
  left: 100px;

  @include media-breakpoint-up(md) {
    left: 100px;
  }
  @include media-breakpoint-up(lg) {
    left: 200px;
  }
}
