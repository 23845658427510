.card.hero {
  @include media-breakpoint-up(lg) {
    height: 22rem;
  }
  @include media-breakpoint-down(md) {
    height: 19.5rem;
  }
  @include media-breakpoint-down(sm) {
    height: 15rem;
  }

  img {
    height: 100%;
    width: auto;
    object-fit: cover;
    object-position: right center;
  }

  .card-img-overlay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

      .hero-title {
        font-family: 'Poppins', sans-serif !important;
        display: inline-block;
        padding: 0.2rem 1.2rem;
        font-weight: 600;
        font-style: normal;
        margin-bottom: #{$spacer};

        @include themify($themes) {
          color: $hcdarkblue;
          background-color: white;
        }

        &:last-child {
          margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
          font-size: 3.5rem;
        }
        @include media-breakpoint-down(md) {
          font-size: 3rem;
        }
        @include media-breakpoint-down(sm) {
          font-size: 2rem;
        }
      }
  }
}
