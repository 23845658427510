.no-js img.lazyload {
display: none;
}

.blur-up {
filter: blur(10px);
transition: filter 400ms;
}

.blur-up.lazyloaded {
filter: blur(0);
}

.fade-in {
opacity: 0;
transition: opacity 400ms;
}

.fade-in.lazyloaded {
opacity: 1;
}
