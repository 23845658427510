@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap");

html {
  overflow-x: hidden;
  @include themify($themes) {
    color: themed("primary");
  }
}

body {
  overflow-x: hidden;
  font-weight: 300;
  font-style: normal;
  margin: 0;
  @include themify($themes) {
    background-color: themed("body");
    color: themed("primary");
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-style: normal;
  @include themify($themes) {
    color: themed("primary");
  }
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
}

h1,
h2 {
  font-weight: 600;
  font-family: "Oswald", sans-serif !important;
  text-transform: uppercase;
}

h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}

/* h1:before {
  content: "";
  position: absolute;
  width: 3*$spacer;
  height: 0.1rem;
  bottom: -0.1rem;
  left: 0;
  @include themify($themes) {
    border-bottom: 0.1rem solid themed('secondary');
  }
} */

p {
  @include themify($themes) {
    color: themed("text");
  }
}

a {
  text-decoration: none;
  @include themify($themes) {
    color: themed("primary");
  }

  &:hover {
    text-decoration: none;
    @include themify($themes) {
      color: themed("active");
    }
  }
}

h6 {
  margin: 1rem 0 0;
}

.count,
.count ~ span {
  font-weight: 300;
  font-size: 2rem;
  font-style: normal;
}

.card {
  @include themify($themes) {
    background-color: themed("background");
    border-color: themed("border");
    color: themed("primary");
  }
}

.btn-primary {
  background-color: $hcdarkblue;
  border-color: $hcdarkblue;
  border-width: 1.5px;
  color: white;
  border-radius: 40px;
  font-weight: bold;
  &:hover {
    border-color: $hcdarkblue;
    background-color: $white;
    color: $hcdarkblue;
  }
}

.btn-secondary {
  background-color: $hclightblue;
  border-color: $hclightblue;
  border-width: 1.5px;
  color: white;
  border-radius: 40px;
  font-weight: bold;

  &:hover {
    border-color: $hclightblue;
    background-color: $white;
    color: $hclightblue;
  }
}

.btn-secondary-inverted {
  background-color: white;
  border-color: white;
  border-width: 1.5px;
  color: $hclightblue;
  border-radius: 40px;
  font-weight: bold;

  &:hover {
    border-color: white;
    background-color: $hclightblue;
    color: white;
  }
}

.text-muted {
  @include themify($themes) {
    color: themed("text-muted") !important;
  }
}

.list-group-item {
  @include themify($themes) {
    background-color: themed("background");
    border-color: themed("border");
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@for $i from 1 through 10 {
  .kuratoren_logo_container img:nth-child(#{$i}n) {
    animation: 0.2s fadeIn;
    animation-delay: #{$i * 0.5}s;
    margin: 0;
    @include themify($themes) {
      background-color: white;
    }
  }
}

.alert-primary {
  @include themify($themes) {
    background-color: themed("button");
    border-color: themed("border");
    color: white;
  }
  a {
    @include themify($themes) {
      color: white;
    }
  }
}

#podcast_link {
  border-bottom: 2px solid #1db954;
}

#podcast_link:hover {
  background-color: #1db954;
}

.card-text-shadow {
  text-shadow: 1px 1px #000f28;
}

.carousel-index {
  height: 350px;
  @include media-breakpoint-up(md) {
    height: 180px;
  }
}

.carousel-unternehmen {
  height: 900px;
  @include media-breakpoint-up(md) {
    height: 450px;
  }
  @include media-breakpoint-up(lg) {
    height: 330px;
  }
}

.scalable_font {
  @include media-breakpoint-down(sm) {
    font-size: smaller;
  }
}

.scalable_font_studierende {
  @include media-breakpoint-down(sm) {
    font-size: medium;
  }
}

.line_break {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.video_title {
  font-size: 6rem;
  text-shadow: 2px 2px #000f28;
  @include media-breakpoint-down(sm) {
    font-size: 4rem;
  }
}

.video_subtitle {
  font-size: 1.5rem;
  font-weight: 600;
  text-shadow: 1px 1px #000f28;
  @include media-breakpoint-down(sm) {
    font-size: 1.2rem;
  }
}

.btn-secondary-accordion {
  background-color: white;
  color: $hcdarkblue;
  //font-weight: bold;
  font-size: large;
  //border-color: $hclightblue;
  //border-width: 1.5px;
  //border-radius: 40px;

  &:hover {
    background-color: white;
    color: $hcdarkblue;
    font-weight: bold;
  }
}

.beratungsfeld_mobile {
  display: block;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.beratungsfeld_desktop {
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
  }
}
