.primary {
fill-rule: nonzero;
  @include themify($themes) {
    fill: themed('primary');
  }
}

.primary-invert {
fill-rule: nonzero;
  @include themify($themes) {
    fill: themed('background');
  }
}

.secondary {
fill-rule: nonzero;
  @include themify($themes) {
    fill: themed('secondary');
  }
}
