$hcdarkblue:    #000f28;
$hclightblue:   #5faaff;
$hcblue:        #00328f;
$hcgrey:        #e4e6e9;
$hcred:         #de323c;
$hcyellow:      #ffaf14;
$hcgreen:       #00c878;

$hcactiveblue:  #5faaff;
$darkmodebase:  $hcdarkblue;

$themes: (
  light: (
    primary: $hcdarkblue,
    secondary: $hclightblue,
    active: $hclightblue,
    background: white,
    border: rgba( black, .125 ),
    body: rgba( $hcdarkblue, .95),
    content: #eff3f7,
    button: $hcdarkblue,
    text-muted: #6c757d,
  ),
  dark: (
    primary: white,
    secondary: $hclightblue,
    active: $hclightblue,
    background: $darkmodebase,
    border: rgba( white, .125 ),
    body: darken( $darkmodebase, 4% ),
    content: darken( $darkmodebase, 2% ),
    button: rgba( white, .125 ),
    text-muted: white,
  ),
);

/*
  Implementation
*/
@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
