/* $accordion-padding-y: 1rem;
$accordion-padding-x: 1.25rem;
$accordion-color: $body-color;
$accordion-bg: $body-bg;
$accordion-border-width: $border-width;
$accordion-border-color: rgba($black, 0.125);
$accordion-border-radius: $border-radius;
$accordion-inner-border-radius: subtract(
  $accordion-border-radius,
  $accordion-border-width
);

$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;

$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: $accordion-color;
$accordion-button-bg: $accordion-bg;
$accordion-transition: $btn-transition, border-radius 0.15s ease;
$accordion-button-active-bg: tint-color($component-active-bg, 90%);
$accordion-button-active-color: shade-color($primary, 10%);

$accordion-button-focus-border-color: $input-focus-border-color;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow;

$accordion-icon-width: 1.25rem;
$accordion-icon-color: $accordion-button-color;
$accordion-icon-active-color: $accordion-button-active-color;
$accordion-icon-transition: transform 0.2s ease-in-out;
$accordion-icon-transform: rotate(-180deg);
*/

//$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
//$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

.card-header_accordion {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius(
      $card-inner-border-radius $card-inner-border-radius 0 0
    );
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }
}

//
// Base styles
//
/* 
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  @include font-size($font-size-base);
  color: $accordion-button-color;
  text-align: left; // Reset button style
  background-color: $accordion-button-bg;
  border: 0;
  @include border-radius(0);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &:not(.collapsed) {
    color: $accordion-button-active-color;
    background-color: $accordion-button-active-bg;
    box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;

    &::after {
      background-image: escape-svg($accordion-button-active-icon);
      transform: $accordion-icon-transform;
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: $accordion-icon-width;
    height: $accordion-icon-width;
    margin-left: auto;
    content: "";
    background-image: escape-svg($accordion-button-icon);
    background-repeat: no-repeat;
    background-size: $accordion-icon-width;
    @include transition($accordion-icon-transition);
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    border-color: $accordion-button-focus-border-color;
    outline: 0;
    box-shadow: $accordion-button-focus-box-shadow;
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: $accordion-bg;
  border: $accordion-border-width solid $accordion-border-color;

  &:first-of-type {
    @include border-top-radius($accordion-border-radius);

    .accordion-button {
      @include border-top-radius($accordion-inner-border-radius);
    }
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  // Only set a border-radius on the last item if the accordion is collapsed
  &:last-of-type {
    @include border-bottom-radius($accordion-border-radius);

    .accordion-button {
      &.collapsed {
        @include border-bottom-radius($accordion-inner-border-radius);
      }
    }

    .accordion-collapse {
      @include border-bottom-radius($accordion-border-radius);
    }
  }
}

.accordion-body {
  padding: $accordion-body-padding-y $accordion-body-padding-x;
}

// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  .accordion-collapse {
    border-width: 0;
  }

  .accordion-item {
    border-right: 0;
    border-left: 0;
    @include border-radius(0);

    &:first-child {
      border-top: 0;
    }
    &:last-child {
      border-bottom: 0;
    }

    .accordion-button {
      @include border-radius(0);
    }
  }
}

// scss-docs-start collapse-classes
.collapse {
  &:not(.show) {
    display: none;
  }
} */
