/* svg:not(:root).svg-inline--fa {
    overflow: visible;
}
.svg-inline--fa.fa-w-16 {
    width: 1em;
}
.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
}
svg {
    overflow: hidden;
    vertical-align: middle;
}
 */
.icon_animate:hover {
  img {
    transition: 300ms;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
}

.icon_animate {
  img {
    box-shadow: none;
    transition: 300ms;
  }
}
