footer {
  @extend .container;
  color: white;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-up(lg) {
    padding: 30px;
  }
  @include media-breakpoint-down(md) {
    padding: 15px 30px 30px 30px;
  }
  width: 100%;

  h3,
  p {
    @include themify($themes) {
      color: white;
    }
    margin-bottom: 0;
  }

  a {
    @include themify($themes) {
      color: white;
    }
    text-decoration: none;

    .svg-inline--fa {
      margin: 0 10px;
    }

    &:hover {
      text-decoration: none;
      @include themify($themes) {
        color: themed("active");
      }
    }
  }
}
